import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  ListGroup,
  Modal,
  Spinner,
  Form,
  Image,
  // Alert,
} from "react-bootstrap";
import axios from "axios";
import serverConfig from "./config";
import JitsiWindow from "./Jitsi-Window";
import DialogViewImage from "./assets/images/dv-logo.png";
import { Avatar, List, Alert } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import Conference from "./components/Conference";
import MeetingImg from "./assets/images/meeting.png";
import ErrorImg from "./assets/images/404.png";
import {
  ADMIN_SERVICE,
  AGENT_SERVICE,
  AUTHENTICATION_SERVICE,
} from "./environment/environment";

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

// Conference details Card style
const style = {
  border: "2px solid #3366ff",
  borderRadius: "10px",
  padding: "1.5rem",
  marginBottom: "1rem",
  textAlign: "center",
  backgroundColor: "#f0f0f0",
  margin: "5px",
};

const urlParams = new URLSearchParams(window.location.search);

export default class JitsiConference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      meetingId: "",
      startMeet: false,
      openModal: false,
      meetingDetails: {},
      status: "",
      showAlert: false,
      showWaiting: false,
      alertMessage: "",
      alertType: "",
      password: "",
      companyLogoURL: "Company Logo",
      showPassword: false,
    };
  }

  UNSAFE_componentWillMount() {
    console.log(
      "serverConfig --->",
      AUTHENTICATION_SERVICE,
      AGENT_SERVICE,
      ADMIN_SERVICE
    );
    console.log("parameters", urlParams);
    console.log(">>>>>>>>>>>>>>>", params);
    console.log("meetingid", params.mn);
    console.log("meetingidpassword", params.pd);

    let meetingId = params.mn ? params.mn : null;
    let password = params.pd ? params.pd : null;
    let companyFancyName = this.getCompanyFancyName();
    this.setState({
      meetingId: meetingId,
      password: password,
    });
    console.log("state------------------->", this.state);
    console.log("companyFancyName:", companyFancyName);
    if (meetingId && password && companyFancyName) {
      console.log(
        "meeting id,password, company logo= are present from url ",
        meetingId,
        password,
        companyFancyName
      );
      console.log(meetingId, password);
      this.getCompanyDetails(companyFancyName);
      this.setState({ status: "success" });
    } else if (companyFancyName) {
      console.log(
        "meeting id,password are missing from url and company logo is present",
        meetingId,
        password,
        companyFancyName
      );
      this.getCompanyDetails(companyFancyName);
      console.log(
        "-----------------------",
        this.getCompanyDetails(companyFancyName)
      );
      this.setState({ status: "success" });
    } else {
      console.log("company logo, meeting id,password are missing from url");
      this.setState({
        companyLogoURL: DialogViewImage,
        status: "success",
      });
    }

    // getWindowHeight
    this.getWindowHeight();
  }
  getCompanyFancyName = () => {
    console.log("finding fancy name");
    const url = window.location.href; // Get the current URL
    // Create a URL object
    const urlObject = new URL(url);
    // Extract the path (including the "/") from the URL
    const path = urlObject.pathname;
    // Use regular expression to extract the name from the path
    const match = path.match(/\/([^/]+)$/);
    if (match) {
      // The extracted name is in match[1]
      const name = match[1];
      console.log(name);
      return name;
    }
  };
  togglePasswordVisibility = () => {
    console.log(this.state.showPassword);
    this.setState({ showPassword: !this.state.showPassword });
  };
  getCompanyDetails = (fancyName) => {
    try {
      let URL = `${AUTHENTICATION_SERVICE}api/Company/logo/${fancyName}`;
      let config = {
        method: "get",
        url: URL,
        headers: {
          // 'token': await token(),
          accept: "*/*",
          "Content-Type": "application/json",
        },
      };
      axios(config).then((res) => {
        console.log("company logo res. ->", res);
        if (res.data.data) {
          this.setState({
            companyLogoURL: res.data.data,
          });
        } else {
          this.setState({
            companyLogoURL: DialogViewImage,
          });
        }
      });
    } catch (e) {
      console.log("Error ::", e);
    }
  };

  // getCompanyDetails = () => {
  //   try {
  //     let companyId = params.companyId.toString().replace(/"/g, "") || "";
  // let URL = `${ADMIN_SERVICE}api/v1/customization/company/${companyId}`;
  //     let config = {
  //       method: "get",
  //       url: URL,
  //       headers: {
  //         // 'token': await token(),
  //         accept: "*/*",
  //         "Content-Type": "application/json",
  //       },
  //     };
  //     axios(config).then((res) => {
  //       console.log("company logo res. ->", res);
  //       if (res.data.data) {
  //         this.setState({
  //           companyLogoURL: res.data.data.companyConfig.companyLogo,
  //         });
  //       } else {
  //       }
  //     });
  //   } catch (e) {}
  // };

  // validatePassword = (reload = "") => {
  //   try {
  //     this.getMeetingDetails(() => {
  //       let appointmentId = params.appointmentId ? params.appointmentId : null;
  //       let meetingId = params.meetingId ? params.meetingId : null;
  // let URL = `${AGENT_SERVICE}api/v1/appointment/validate`;
  //       let config = {
  //         method: "post",
  //         url: URL,
  //         headers: {
  //           // 'token': await token(),
  //           accept: "*/*",
  //           "Content-Type": "application/json",
  //         },
  //         data: {
  //           id: appointmentId,
  //           password: this.state.password,
  //         },
  //       };
  //       axios(config)
  //         .then((res) => {
  //           console.log("validatePassword res ->", res);
  //           if (res.data.error) {
  //             this.setState({
  //               showAlert: true,
  //               alertMessage: "Incorrect Password.",
  //               alertType: "error",
  //             });
  //           }else
  //            {
  //             //check for call started by agent
  //             let meetingDetails = res.data.data;
  //             if (meetingDetails && meetingDetails.callStarted) {
  //               // if success load jitsi video window
  //               if (meetingId && meetingId == this.state.meetingId) {
  //                 this.setState(
  //                   {
  //                     status: "",
  //                     openModal: true,
  //                     showAlert: false,
  //                   },
  //                   () => {
  //                     // clear the interval
  //                     if (this.state.waitInterval) {
  //                       clearInterval(this.state.waitInterval);
  //                     }
  //                   }
  //                 );
  //               } else {
  //                 this.setState({
  //                   showAlert: true,
  //                   alertMessage: "Invalid Meeting ID.",
  //                   alertType: "error",
  //                 });
  //               }
  //             } else {
  //               //check for ongoing call
  //               this.checkIsMeetOngoing((isOnGoing) => {
  //                 if (isOnGoing) {
  //                   this.setState(
  //                     {
  //                       status: "",
  //                       openModal: true,
  //                       showAlert: false,
  //                     },
  //                     () => {
  //                       // clear the interval
  //                       if (this.state.waitInterval) {
  //                         clearInterval(this.state.waitInterval);
  //                       }
  //                     }
  //                   );
  //                 }
  //               });
  //               this.setState(
  //                 {
  //                   showAlert: true,
  //                   showWaiting: true,
  //                   status: "waiting",
  //                   alertMessage:
  //                     "Please wait the admin has not been started the meeting.",
  //                   alertType: "error",
  //                 },
  //                 () => {
  //                   if (!this.state.waitInterval) {
  //                     // start timer until agent is started the call
  //                     let waitInterval = setInterval(() => {
  //                       this.validatePassword("reload");
  //                     }, 10000);
  //                     this.setState({ waitInterval: waitInterval });
  //                   }
  //                 }
  //               );
  //             }
  //           }
  //         })
  //         .catch((error) => {
  //           this.setState({
  //             showAlert: true,
  //             alertMessage: "Incorrect Password.",
  //             alertType: "error",
  //           });
  //         });
  //     }, reload);
  //   } catch (e) {
  //     this.setState({
  //       showAlert: true,
  //       alertMessage: "Authentication failed.",
  //       alertType: "error",
  //     });
  //   }
  // };

  getConferenceDetails = (meetingId, password, callback, reload = "") => {
    try {
      let URL = `${AGENT_SERVICE}api/v1/appointment/meeting/validate`;
      let data = JSON.stringify({
        meetingId: meetingId,
        password: password,
      });
      let config = {
        method: "POST",
        url: URL,
        headers: {
          // 'token': await token(),
          accept: "*/*",
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then((res) => {
          console.log("appointment res ->", res);
          if (res.data.error) {
            this.setState({
              companyLogoURL: this.state.companyLogoURL
                ? this.state.companyLogoURL
                : DialogViewImage,
              showAlert: true,
              alertMessage: res.data?.errorList[0]?.errWrnDescription,
              alertType: "error",
            });
            callback();
          } else {
            console.log("appointment id", res.data.data.id);
            this.getMeetingDetails(
              res.data.data.id,
              () => {
                //check for call started by agent
                let meetingDetails = res.data.data;
                if (meetingDetails && meetingDetails.callStarted) {
                  // if success load jitsi video window
                  if (meetingId && meetingId == this.state.meetingId) {
                    this.setState(
                      {
                        status: "",
                        openModal: true,
                        showAlert: false,
                      },
                      () => {
                        // clear the interval
                        if (this.state.waitInterval) {
                          clearInterval(this.state.waitInterval);
                        }
                      }
                    );
                  } else {
                    this.setState({
                      showAlert: true,
                      alertMessage: "Invalid Meeting ID.",
                      alertType: "error",
                    });
                  }
                } else {
                  console.log(
                    "check for ongoing call",
                    this.checkIsMeetOngoing()
                  );
                  //check for ongoing call
                  this.checkIsMeetOngoing((isOnGoing) => {
                    if (isOnGoing) {
                      console.log("ongoing call");
                      this.setState(
                        {
                          status: "",
                          openModal: true,
                          showAlert: false,
                        },
                        () => {
                          // clear the interval
                          if (this.state.waitInterval) {
                            clearInterval(this.state.waitInterval);
                          }
                        }
                      );
                    }
                  });
                  this.setState(
                    {
                      showAlert: true,
                      showWaiting: true,
                      status: "waiting",
                      alertMessage:
                        "Please wait the admin has not been started the meeting.",
                      alertType: "error",
                    },
                    () => {
                      console.log(
                        "this.state.waitInterval:",
                        this.state.waitInterval
                      );
                      if (!this.state.waitInterval) {
                        // start timer until agent is started the call
                        let waitInterval = setInterval(() => {
                          this.getConferenceDetails(
                            this.state.meetingId,
                            this.state.password,
                            () => {},
                            "reload"
                          );
                        }, 10000);
                        this.setState({ waitInterval: waitInterval });
                      } else {
                        this.getConferenceDetails(
                          this.state.meetingId,
                          this.state.password,
                          () => {},
                          "reload"
                        );
                      }
                    }
                  );
                }
              },
              "reload"
            );
          }
        })
        .catch((apiError) => {
          console.error("API ERROR::", apiError);
          this.setState({ status: "error" });
          this.setState({
            companyLogoURL: DialogViewImage,
          });
          callback();
        });
    } catch (error) {
      this.setState({
        showAlert: true,
        alertMessage: "Authentication failed.",
        alertType: "error",
      });
    }
  };

  getMeetingDetails = (appointmentId, callback, reload = "") => {
    try {
      let appID = appointmentId.toString().replace(/"/g, "");
      console.log("APPID -->", appID);
      let URL = `${AGENT_SERVICE}api/v1/appointment/view/${appID}`;

      let config = {
        method: "get",
        url: URL,
        headers: {
          // 'token': await token(),
          accept: "*/*",
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then((res) => {
          console.log("appointment res ->", res);
          if (res.data.error) {
            this.setState({ status: "error" });
            this.setState({
              companyLogoURL: DialogViewImage,
            });
            callback();
          } else {
            let meetingDetails = {};
            Object.assign(meetingDetails, res.data.data);
            this.setState({ meetingDetails: meetingDetails }, () => {
              console.log(
                "meetingDetails from state ->",
                this.state.meetingDetails
              );
              if (!reload) {
                this.setState({ status: "success" });
              }
              callback();
            });
          }
        })
        .catch((apiError) => {
          console.error("API ERROR::", apiError);
          this.setState({ status: "error" });
          this.setState({
            companyLogoURL: DialogViewImage,
          });
          callback();
        });
    } catch (error) {
      callback();
      console.error("ERROR::", error);
    }
  };

  checkIsMeetOngoing = (callback) => {
    console.log("checking for ongoing meet ........");
    const options = {
      roomName: this.state.meetingId,
      width: "100%",
      height: window.innerHeight - 150,
      configOverwrite: {
        prejoinPageEnabled: false,
        startWithVideoMuted: true,
        startWithAudioMuted: true,
        startVideoMuted: 4,
        startWithVideoMuted: true,
      },
      interfaceConfigOverwrite: {
        // overwrite interface properties
        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
        MOBILE_APP_PROMO: false,
        TOOLBAR_BUTTONS: [
          "fullscreen",
          "microphone",
          "camera",
          "closedcaptions",
          "desktop",
          // "embedmeeting",
          "fodeviceselection",
          "hangup",
          "profile",
          "chat",
          "recording",
          "livestreaming",
          // "etherpad",
          // "sharedvideo",
          // "shareaudio",
          // "settings",
          "raisehand",
          "videoquality",
          "filmstrip",
          // "invite",
          "feedback",
          // "stats",
          "shortcuts",
          "tileview",
          "select-background",
          "download",
          // "security",
          "whiteboard",
        ],
      },
      parentNode: document.querySelector("#jitsi-iframe"),
      userInfo: {
        displayName: this.state.userName,
      },
      onload: () => {
        console.log("Loaded jitsi for checking ongoing meeting");
        setTimeout(() => {
          console.log(
            "No-Of-Participants =",
            this.api.getNumberOfParticipants()
          );
          if (this.api.getNumberOfParticipants() > 1) {
            callback(true);
          } else {
            callback(false);
          }
        }, 10000);
      },
    };
    if (!this.api) {
      this.api = new window.JitsiMeetExternalAPI(
        "meet-dev.dialogview.ai",
        options
      );
      console.log("starting jitsi ....");
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit information", e);
    console.log("this.state -->", this.state);
    // start conference
    this.getConferenceDetails(
      this.state.meetingId,
      this.state.password,
      () => {}
    );
  };

  getWindowHeight = () => {
    let innerHeight = window.innerHeight;
    console.log("innerHeight: ", innerHeight);

    window.addEventListener("resize", () => {
      // console.log("window --> ", window);
      console.log("window height: ", window.innerHeight);
      console.log("window width: ", window.innerWidth);
    });
    return innerHeight;
  };

  render() {
    const {
      startMeet,
      meetingDetails,
      status,
      meetingId,
      password,
      userName,
      showPassword,
    } = this.state;
    return (
      <div>
        {this.state.openModal ? (
          <Conference
            meetingId={this.state.meetingId}
            userName={this.state.userName}
            companyLogoURL={this.state.companyLogoURL}
            closeModal={() =>
              this.setState({ openModal: false, status: "success" })
            }
            meetingDetails={
              this.state.meetingDetails || { summary: "Conference Call" }
            }
          />
        ) : (
          <div style={{ margin: "2%", height: "90vh" }}>
            {/* header */}

            <div class="d-flex justify-content-between">
              <div className="company-logo">
                <img
                  src={
                    this.state.companyLogoURL
                      ? this.state.companyLogoURL
                      : DialogViewImage
                  }
                  // style="width: 300px; height: 200px; border: 2px solid #333; box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);"
                  style={{
                    // borderRadius: "50%",
                    width: "auto",
                    height: "10vh",
                    // borderColor: "#333",
                    // boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)",
                    objectFit: "contain",
                    padding: "5px",
                  }}
                  width="auto"
                  height={50}
                  alt="Company LOGO"
                />
              </div>
            </div>
            <div id="jitsi-iframe" style={{ display: "none" }}></div>
            {/* conference details */}

            <div className="">
              {/* loading */}
              {status == "" ? (
                <div id="loading-wrapper">
                  <div id="loading-text">LOADING</div>
                  <div id="loading-content"></div>
                  {/* <div id="loading">{this.state.alertMessage}</div> */}
                </div>
              ) : status == "waiting" ? (
                <div className="center-screen">
                  <div>
                    <h3>Please wait until the host starts the meeting</h3>
                  </div>
                  <br />
                  <div>
                    <div className="loader-outer">
                      <div className="loader">
                        <span className="dot dot-1"></span>
                        <span className="dot dot-2"></span>
                        <span className="dot dot-3"></span>
                        <span className="dot dot-4"></span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : status == "error" ? (
                // error
                <div className="container_error">
                  <img className="errorimg" alt="not found" src={ErrorImg} />
                  <h4>
                    You've found a page that doesn't exist. Breath in, and on
                    the out breath, go back and try again.
                  </h4>
                </div>
              ) : (
                // success
                <div>
                  <div className="container_success">
                    <div className="side left">
                      <div className="container_form">
                        {this.state.showAlert && (
                          <Alert
                            style={{ fontSize: "1.1em" }}
                            message={this.state.alertMessage}
                            type={this.state.alertType}
                            showIcon
                            closable
                          />
                        )}
                        <Form onSubmit={this.handleSubmit}>
                          <div className="container_form_input">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Meeting ID</Form.Label>
                              <Form.Control
                                disabled={params.mn ? true : false} // Meeting id cannot be changed
                                onChange={(e) =>
                                  this.setState({
                                    meetingId: e.target.value,
                                    showAlert: false,
                                  })
                                }
                                value={meetingId || ""}
                                type="text"
                                placeholder="Please enter meeting ID"
                              />
                            </Form.Group>
                          </div>
                          <div className="container_form_input">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                onChange={(e) =>
                                  this.setState({
                                    userName: e.target.value,
                                    showAlert: false,
                                  })
                                }
                                type="text"
                                value={userName || ""}
                                placeholder="Please enter your name"
                                required
                              />
                            </Form.Group>
                          </div>

                          <div className="container_form_input">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label className="control-label">
                                Meeting Password
                              </Form.Label>
                              <div style={{ position: "relative" }}>
                                <Form.Control
                                  required
                                  onChange={(e) =>
                                    this.setState({
                                      password: e.target.value,
                                      showAlert: false,
                                    })
                                  }
                                  value={password || ""}
                                  type={
                                    this.state.showPassword
                                      ? "text"
                                      : "password"
                                  }
                                  placeholder="Please enter meeting password"
                                />
                                {showPassword ? (
                                  <EyeOutlined
                                    className="eye-icon"
                                    style={{
                                      position: "absolute",
                                      top: "30%",
                                      right: "10px",
                                    }}
                                    onClick={this.togglePasswordVisibility}
                                  />
                                ) : (
                                  <EyeInvisibleOutlined
                                    className="eye-icon"
                                    style={{
                                      position: "absolute",
                                      top: "30%",
                                      right: "10px",
                                    }}
                                    onClick={this.togglePasswordVisibility}
                                  />
                                )}
                              </div>
                            </Form.Group>
                          </div>

                          <div className="container_button">
                            <Button variant="primary" block type="submit">
                              Join Conference
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>

                    <div className="side right">
                      <div>
                        <div className="contain">
                          <h2>Connect Securely</h2>
                          <h4>
                            Start secure, high-quality video conversation that
                            helps connect users worldwide on any device.
                          </h4>
                        </div>
                        <div className="image_container">
                          <img
                            src={MeetingImg}
                            className="container_success_img"
                            alt="meeting"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <footer className="footer">
              Powered By&nbsp;
              <span>
                <a href="https://dialogview.com" target="_blank">
                  DialogView
                </a>
              </span>
            </footer>

            {/* <Modal.Body>
             */}
          </div>
        )}
      </div>
    );
  }
}
